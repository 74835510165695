import { createFromIconfontCN } from '@ant-design/icons-vue';

// 定义iconfont库
let iconfontVersion = ['2769891_yystw9j0hb']
let iconfontUrlCss = `//at.alicdn.com/t/font_$key.css`
let iconfontUrljs = `//at.alicdn.com/t/font_$key.js`

// 定义动态插入方法
const loadStyle = url => {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}

// 新增 导出
export const IconFont = createFromIconfontCN({
    scriptUrl: iconfontUrljs.replace('$key', iconfontVersion[0])
})

// 动态插入
iconfontVersion.forEach(ele => {
    loadStyle(iconfontUrlCss.replace('$key', ele))
})
